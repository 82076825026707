.w-100 {
    width: 100%;

}
.MuiTypography-root {
    font-family: "Noto IKEA, Noto Sans, Roboto, Open Sans, system-ui, sans-serif !important"
}

#datepickerheight {
    height: 47px !important
}

input#\:r4\: {
    height: 47px !important;
}

._audit_form_header {
    text-align: center;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    color: #ffffff !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #ffce00 !important;
    font-weight: 600;
}

.MuiAppBar-colorPrimary {
    color: #fff;
    background-color: #0257a7 !important;
}

.MuiTypography-h4 {
    color: #f4fff4;
}

.align-self-center {
    align-self: center;
}

.text-end {
    text-align: end;
}

/* .question_top {
    margin: 0.5rem 0rem 0.5rem 1rem;
    background: #ffffff;
    padding: 0.45rem 1rem !important;
    border: 1px solid #ccc;
} */

.question_top .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 0;
}

.css-mhc70k-MuiGrid-root {
    margin-left: unset !important;
    width: 100% !important;
}

.quetio_main {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.makeStyles-radioButton-110:checked {
    border: none;
    background-color: #ffce00 !important;
    color: #0257a7;
    font-weight: 800;
}

.makeStyles-radioLabel-111 {
    bottom: 10px !important;
    margin-left: -50px !important;
    font-weight: 600 !important;
    color: #0257a7 !important;
}

.css-41cwb0-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    width: 100% !important;
}
.MuiFormHelperText-root{
    color:red !important;
}

hr {
    border: none;
    border-bottom: 1px solid #dde2eb;
    height: unset;
    width: 100%;
    margin: 1rem 1rem;
}

.css-mym57r {
    padding: 1rem 1rem 0rem !important;
}

.MuiListItem-button {
    box-shadow: 0px 1px 5px #e5e3e3 !important;
    margin-bottom: 1vh !important;
}

.MuiCollapse-entered .MuiListItem-button {
    box-shadow: none !important;
}


@media (min-width: 1200px) {
    .MuiGrid-grid-xs-12 {
        max-width: 100%;
        padding: 0rem 1.05rem;
    }
}


@media only screen and (min-width:540px) and (max-width:769px) {
    ._audit_form form {
        padding-left: 6rem;
        padding-right: 6rem;
        padding-bottom: 6rem;
    }

}

._audit_form {
    margin-bottom: 8% !important;
}

/* smaller screen laptop */
/* mahendran laptop */
/* @media (min-width: 1200px) and (max-width: 1599px) {
    #aggridheight {
        height: 68vh !important;
    }

    #sendforbutton {
        height: 60vh !important;
    }
} */



/* @media (min-width: 1157px) and (max-width:1440px) {
    #aggridheight {
        height: 77vh !important;
    }
} */

#sendforbutton {
    height: 65vh;
}

#aggridheight {
    height: 59vh !important
}

.goto_reports {
    justify-content: flex-end !important;
    display: flex !important;
}

/* bigger laptop */
@media (min-width: 1600px) {
    #aggridheight {
        height: 73vh !important
    }

    #accordionStyle {
        height: 75vh !important;
        /* margin-bottom: 25px !important; */
    }

    #sendforbutton {
        height: 43vh !important;
    }

    /* .goto_reports {
        justify-content: end !important;
        display: flex !important;
    } */


}

@media only screen and (min-width: 1281px) and (max-width: 1400px) {
    #accordionStyle {
        height: 65vh !important;
        /* margin-bottom: 45px !important; */
    }
    #aggridheight {
        height: 59vh !important
    }
  }

/* my laptop */
@media (min-width: 1400px) and (max-width: 1439px) {
    #accordionStyle {
        height: 70vh !important;
        /* margin-bottom: 45px !important; */
    }

    #aggridheight {
        height: 59vh !important
    }

    /* .goto_reports {
        justify-content: end !important;
        display: flex !important;
    } */
}

/* mahendran laptop */
@media (min-width: 1440px) and (max-width:1599px) {
    #accordionStyle {
        height: 72vh !important;
        /* margin-bottom: 25px !important; */
    }

    #sendforbutton {
        height: 75vh !important;
    }

    #aggridheight {
        height: 73vh !important;
    }

    .goto_reports {
        justify-content: flex-end !important;
        display: flex !important;
    }

    /* .question_top {
        margin: 0rem 0rem 0.5rem 1rem;
        background: #ffffff;
        padding: 0.45rem 1rem !important;
        border: 1px solid #ccc;
    } */
}

@media (min-width: 600px) and (max-width: 1199px) and (orientation: landscape) {
    #accordionStyle {
        height: 575px !important;
        /* margin-bottom: 25px !important; */
    }

    #aggridheight {
        height: 73vh !important
    }

    #sendforbutton {
        height: 80vh !important;
    }

    /* #backSubmitButtons {
        margin-bottom: 40px !important;
    } */

    .goto_reports {
        justify-content: center !important;
        display: flex !important;
        /* left: 34px !important; */
        /* position: relative !important; */
    }

}
@media (min-width: 600px) and (max-width: 1199px) and (orientation: portrait) {

#sendforbutton {
    height: 85vh !important;
}
}

@media (min-width: 600px) and (max-width: 1199px) and (orientation: portrait) {
    #accordionStyle {
        height: 575px !important;
        /* margin-bottom: 25px !important; */
    }

    #aggridheight {
        height: 73vh !important
    }

    /* #backSubmitButtons {
        margin-bottom: 40px !important;
    } */

}

@media (min-width: 320px) and (max-width: 770px) and (orientation: landscape) {
    #aggridheight {
        height: 35vh !important
    }
    #sendforbutton {
        height: 94vh !important;
    }
    #accordionStyle {
        height: 125px !important;
        /* margin-bottom: 25px !important; */
    }

}
@media (min-height: 330px)and (max-height: 400px) and (orientation: landscape) {
    #aggridheight {
        height: 45vh !important
    }
    #accordionStyle {
        height: 170px !important;
        /* margin-bottom: 25px !important; */
    }
}
@media  (min-height: 400px)and (max-height: 450px) and (orientation: landscape) {
    #aggridheight {
        height: 51vh !important
    }
    #accordionStyle {
        height: 235px !important;
        /* margin-bottom: 25px !important; */
    }
}
/* @media  (min-height: 450px) and (orientation: landscape) {
    #aggridheight {
        height: 53vh !important
    }
} */

@media (min-width: 320px) and (max-width: 770px) and (orientation: portrait) {
    ._audit_formik{
        padding-bottom: 55px;
    }
    #sendforbutton {
        height: 45vh !important;
    }
}


@media (min-width: 300px) and (max-width: 599px) {
    #accordionStyle {
        height: 570px !important;
        /* margin-bottom: 25px !important; */
    }

    #sendforbutton {
        height: 55vh !important;
    }

    #aggridheight {
        height: 67vh !important;
    }

    /* #backSubmitButtons {
        margin-bottom: 25px !important;
    } */

    .goto_reports {
        justify-content: flex-end !important;
        display: flex !important;
    }

    .exit_audit {
        justify-content: flex-end !important;
        display: flex !important;
    }
}

@media (min-width: 768px) and (max-width: 768px) and (orientation: portrait) {
    .goto_reports {
        justify-content: center !important;
        display: flex !important;
    }
}

@media print {
    body {
        overflow: visible;
    }
}